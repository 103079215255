import Image, { Rendition } from '@components/Image';

import { WagtailImage } from '@/types';

type Props = {
  image: WagtailImage;
};

function BannerImage(props: Props) {
  const { image } = props;

  return (
    <Image
      className='full-width'
      imageClassName='full-width banner-image'
      image={image}
      priority
      spec='fill-667x400'
    >
      <Rendition image={image} mediaQuery='(min-width: 667px)' spec='fill-1920x500' />
    </Image>
  );
}

export default BannerImage;
