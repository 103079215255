import RawHtml from '@components/RawHtml';
import clsx from 'clsx';
import React from 'react';

type Props = {
  type: 'warning' | 'success' | 'error' | 'information';
  title: string;
  text: string;
};

function Message(props: Props) {
  const { type, title, text } = props;

  return (
    <div
      className={clsx('streamfield', 'message', {
        'message--success': type === 'success',
        'message--error': type === 'error',
        'message--warning': type === 'warning',
        'message--information': type === 'information',
      })}
    >
      <p className='message__title'>{title}</p>
      <RawHtml html={text} />
    </div>
  );
}

export default Message;
