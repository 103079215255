'use client';

import { Fieldset, Input } from '@components/Form';
import Select from '@components/Form/Select';
import Textarea from '@components/Form/Textarea';
import { useTranslations } from 'next-intl';
import React, { useId, useReducer } from 'react';
import Button from '@components/Button';
import Link from 'next/link';

type IdleState = {
  status: 'idle';
};

type LoadingState = {
  status: 'loading';
};

type SuccessState = {
  status: 'success';
  data: any;
};

type ErrorState = {
  status: 'error';
  error: any;
};

export type State = IdleState | LoadingState | SuccessState | ErrorState;

type Props = { title: string; to: string };

function EventRegistrationForm(props: Props) {
  const { title, to } = props;
  const id = useId();
  const t = useTranslations('Common');
  const [state, dispatch] = useReducer((s: State, a: State) => ({ ...s, ...a }), {
    status: 'idle',
  });

  return (
    <form
      className='form'
      onSubmit={async e => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData);

        try {
          dispatch({ status: 'loading' });
          const res = await fetch('/api/events/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({ ...data, to }),
          });

          if (res.ok) {
            dispatch({ status: 'success', data: null });
            return;
          }

          throw new Error();
        } catch (error) {
          console.error(error);
          dispatch({ status: 'error', error: null });
        }
      }}
    >
      <Fieldset className='fieldset' legend={t('Termine')}>
        <div className='form__row'>
          <Input type='date' id={`${id}-date`} name='date' label={t('Wunschtermin')} required />
          <Input
            type='date'
            id={`${id}-alternative-date-1`}
            name='alternative-date-1'
            label={t('Alternativtermin')}
          />
        </div>
        <Input
          type='time'
          id={`${id}-earliest-time`}
          name='earliest-time'
          label={t('frühestmögliche Uhrzeit')}
        />
      </Fieldset>
      <Fieldset className='fieldset' legend={t('Gruppe')}>
        <Input
          type='text'
          id={`${id}-person-count`}
          name='person-count'
          label={t('Anzahl der Personen')}
          placeholder={t('Anzahl der Personen')}
          required
        />
        <Select
          name='language'
          items={[
            { text: t('Deutsch'), value: 'Deutsch' },
            { text: t('Englisch'), value: 'Englisch' },
          ]}
          label={t('Sprache')}
          ariaLabel={t('Sprache')}
        />
      </Fieldset>
      <input type='hidden' name='title' defaultValue={title} id='title' />
      <Fieldset legend={t('Kontaktdaten')} className='fieldset'>
        <Input
          type='text'
          id={`${id}-institution`}
          name='institution'
          label={t('Name der Institution')}
          required
        />
        <Input type='text' id={`${id}-street`} name='street' label={t('Straße')} required />
        <div className='form__row'>
          <Input
            type='text'
            id={`${id}-zip-code`}
            name='zip-code'
            label={t('Postleitzahl')}
            required
          />
          <Input type='text' id={`${id}-city`} name='city' label={t('Stadt')} required />
        </div>
        <Input
          type='text'
          id={`${id}-contact`}
          name='contact'
          label={t('Kontaktperson')}
          required
        />
        <Input type='text' id={`${id}-email`} name='email' label={t('E-Mail')} required />
        <Input
          type='text'
          id={`${id}-contact-phone`}
          name='contact-phone'
          label={t('Telefon Kontaktperson')}
          required
        />
        <Textarea
          id={`${id}-additional-information`}
          name='additional-information'
          label={t('Sonstige Mitteilungen')}
          rows={5}
        />
      </Fieldset>
      <fieldset className='fieldset'>
        <legend>{t('Datenschutz')}</legend>
        <div className='form-field form-field--checkbox'>
          <input
            type='checkbox'
            id={`${id}-data-protection`}
            name='data-protection'
            value='accepted'
          />
          <label htmlFor={`${id}-data-protection`}>
            {t('Datenschutzerklärung zur Kenntnis genommen')}
          </label>
        </div>
      </fieldset>
      {state.status === 'success' ? (
        <div className='message message--success' aria-live='polite'>
          <h2 className='message__title'>Vielen Dank für Ihre Anfrage!</h2>
          <p>Wir bearbeiten Ihre Anfrage schnellstmöglich und melden uns im Anschluss bei Ihnen.</p>
        </div>
      ) : null}
      {state.status === 'error' ? (
        <div className='message message--error' aria-live='polite'>
          <h2 className='message__title'>Leider ist etwas schief gegangen...</h2>
          <p>
            Bitte versuchen Sie es später erneut oder senden uns eine Mail unter der Adresse{' '}
            <Link href='mailto:buchung@neanderthal.de'>buchung@neanderthal.de</Link> zu. Vielen
            Dank.
          </p>
        </div>
      ) : null}
      <Button type='submit' disabled={state.status === 'loading' || state.status === 'success'}>
        {state.status === 'loading' ? t('Übertrage Formulardaten') : t('Absenden')}
      </Button>
    </form>
  );
}

export default EventRegistrationForm;
