/* eslint-disable react/no-array-index-key */
import Image from '@components/Image';

import { WagtailImage } from '@/types';

type Tiles = {
  iconimage: WagtailImage | null;
  infotext?: string;
  tiletext?: string;
};

type Props = {
  title?: string;
  tiles: Tiles[];
};

function FeatureTiles(props: Props) {
  const { tiles, title } = props;
  return (
    <div className='streamfield featuretiles'>
      <h2>{title}</h2>
      <div className='featuretiles__wrapper'>
        {tiles.map((tile, index) => (
          <div className='featuretile' key={index}>
            <div className='featuretile__bgwrapper'>
              {tile.iconimage ? (
                <div className='card__iconwrapper'>
                  <Image
                    image={tile.iconimage}
                    className='featuretile__icon'
                    spec='fill-120x120'
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ) : null}
              <div className='featuretile__text'>{tile.infotext ? tile.infotext : null}</div>
            </div>
            <div className='featuretile__text--small'>{tile.tiletext ? tile.tiletext : null}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureTiles;
