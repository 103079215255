import Checkbox from '@components/Form/Checkbox';
import React from 'react';
import { FormField } from '@/types';

function CheckBoxField(props: FormField) {
  const { label, clean_name: cleanName, required } = props;

  return <Checkbox id={cleanName} value='checked' label={label} required={required} />;
}

export default CheckBoxField;
