import React from 'react';
import Label from '../Label';

type Props = {
  label: string;
  id: string;
  name?: string;
  required?: boolean;
  rows?: number;
  placeholder?: string;
};

function Textarea(props: Props) {
  const { label, id, name, required, placeholder, rows } = props;

  return (
    <div className='textarea'>
      <Label text={`${label}${required ? '' : ' (optional)'}`} htmlFor={id} />
      <textarea
        id={id}
        name={name ?? id}
        required={required}
        rows={rows}
        placeholder={placeholder}
      />
    </div>
  );
}

export default Textarea;
