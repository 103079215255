import React from 'react';
import Image from '@components/Image';
import RawHtml from '@components/RawHtml';
import Link from 'next/link';

import type { GeneralEvent } from './GeneralEvents';
import { useTranslations } from 'next-intl';
import { removeBaseUrl } from '@utils/url';
import Dialog from '@components/Dialog';
import EventRegistrationForm from '@components/EventRegistrationForm';

type Props = { item: GeneralEvent };

function RelatedItem(props: Props) {
  const { item } = props;

  const t = useTranslations('Common');

  return (
    <div className='related-item'>
      <Image
        image={item.image}
        className='related-item__image'
        spec='width-600'
        width={268}
        height={179}
      />
      <div className='related-item__content'>
        <h3 className='related-item__title'>{item.title}</h3>
        {item.subtitle ? (
          <p className='related-item__subtitle'>
            <strong>{item.subtitle}</strong>
          </p>
        ) : null}
        <RawHtml html={item.text} />
        {item?.attachment ? (
          <Link href={item.attachment.file} className='related-item__attachment' target='_blank'>
            {t('Weitere Informationen in diesem PDF')}
          </Link>
        ) : null}
        <div className='related-item__links'>
          {item.detail_page ? (
            <div className='related-item__registration'>
              <Link
                className='button button--outline related-item__link'
                href={removeBaseUrl(item.detail_page.full_url)}
              >
                {t('Mehr erfahren')}
              </Link>
            </div>
          ) : null}
          {item.visitate_link ? (
            <div className='related-item__registration'>
              <Link
                className='button button--outline related-item__link'
                href={item.visitate_link}
                target='_blank'
                rel='noreferrer noopener'
              >
                {t('Zur Online-Buchung')}
              </Link>
            </div>
          ) : null}
          {item.email ? (
            <div className='related-item__registration'>
              <Dialog
                title={item.title}
                description={<RawHtml html={item.text} />}
                trigger={t('Online-Anmeldung')}
              >
                <EventRegistrationForm title={item.title} to={item.email} />
              </Dialog>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default RelatedItem;
