import clsx from 'clsx';
import React from 'react';

type Props = {
  width: string;
  height?: string;
  noMargin?: boolean;
  noBorder?: boolean;
  className?: string;
};

function LoadingSkeleton(props: Props) {
  const { width, height, noMargin, noBorder, className } = props;
  return (
    <div
      aria-hidden
      className={clsx('loading-skeleton', className, {
        'loading-skeleton--no-margin': noMargin,
        'loading-skeleton--no-border': noBorder,
      })}
      style={{ width, height }}
    />
  );
}

export default LoadingSkeleton;
