import Link from 'next/link';

import { removeBaseUrl } from '@/utils/url';

import { WagtailPage } from '@/types';

export type Button = {
  secondary: boolean;
  button_text: string;
  button_page: WagtailPage | null;
  button_url: string | null;
};

type Props = {
  buttons: Button[];
};

function Buttons(props: Props) {
  const { buttons } = props;
  return (
    <div className='streamfield buttons'>
      {buttons.map(button => (
        <Link
          key={button.button_text}
          href={
            button.button_page
              ? removeBaseUrl(button.button_page.full_url)
              : button.button_url ?? ''
          }
          passHref
          className={button.secondary ? 'button button--outline' : 'button'}
        >
          {button.button_text}
        </Link>
      ))}
    </div>
  );
}

export default Buttons;
