import React from 'react';
import * as S from '@radix-ui/react-select';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import Label from '../Label';

type SelectItem = {
  value: string;
  text: string;
  disabled?: boolean;
};

type Props = {
  items: SelectItem[];
  ariaLabel: string;
  label?: string;
  id?: string;
  name?: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
  fluid?: boolean;
};

function Select(props: Props) {
  const { items, id, name, defaultValue, ariaLabel, onValueChange, fluid, label } = props;

  return (
    <div className={clsx('select', { 'select--fluid': fluid })}>
      {label ? <Label htmlFor={name ?? id} text={label} /> : null}
      <S.Root
        defaultValue={defaultValue || items[0]?.value}
        onValueChange={onValueChange}
        name={name ?? id}
      >
        <S.Trigger className='select__trigger' aria-label={ariaLabel} id={name ?? id}>
          <S.Value className='select__value' />
          <S.Icon className='select__icon'>
            <ChevronDownIcon width={28} height={28} color='var(--clr-secondary)' />
          </S.Icon>
        </S.Trigger>
        <S.Portal>
          <S.Content className='select__content'>
            <S.ScrollUpButton>
              <ChevronUpIcon />
            </S.ScrollUpButton>
            <S.Viewport className='select__viewport'>
              {items.map(item => (
                <S.Item
                  key={item.value}
                  className='select__item'
                  value={item.value}
                  disabled={item.disabled}
                >
                  <S.ItemIndicator className='select__item-indicator'>
                    <CheckIcon width={28} height={28} />
                  </S.ItemIndicator>
                  <S.ItemText className='select__item-text'>{item.text}</S.ItemText>
                </S.Item>
              ))}
            </S.Viewport>
            <S.ScrollDownButton>
              <ChevronDownIcon />
            </S.ScrollDownButton>
          </S.Content>
        </S.Portal>
      </S.Root>
    </div>
  );
}

export default Select;
