import React, { InputHTMLAttributes } from 'react';
import Label from '../Label';
import clsx from 'clsx';

type Props = { label: string; id: string; className?: string };

const Input = React.forwardRef<HTMLInputElement, Props & InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { label, className, ...rest } = props;
    return (
      <div className={clsx('input', className)}>
        <Label htmlFor={rest.id} text={`${label}${!rest.required ? ' (optional)' : ''}`} />
        <input ref={ref} {...rest} name={rest?.name ?? rest.id} className='input__element' />
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
