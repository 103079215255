import clsx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';

type Props = {
  outline?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { outline, className, ...rest } = props;

  return (
    <button
      className={clsx(
        'button',
        {
          'button--outline': outline,
        },
        className,
      )}
      ref={ref}
      {...rest}
    />
  );
});

Button.displayName = 'Button';

export default Button;
