import Input from '@components/Form/Input';
import React from 'react';
import { FormField } from '@/types';

function EmailField(props: FormField) {
  const {
    label,
    clean_name: cleanName,
    field_type: fieldType,
    help_text: helpText,
    required,
  } = props;

  return (
    <Input
      label={label}
      type={fieldType}
      id={cleanName}
      placeholder={helpText}
      required={required}
    />
  );
}

export default EmailField;
