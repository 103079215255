/* eslint-disable react/no-array-index-key */
import Image, { Rendition } from '@components/Image';

import Link from 'next/link';

import { WagtailImage, WagtailPage } from '@/types';
import { removeBaseUrl } from '@utils/url';
import clsx from 'clsx';

type LinkTile = {
  title: string;
  button_page: WagtailPage | null;
  button_url: string | null;
  image: WagtailImage | null;
};

type Props = {
  blocktitle: string | null;
  variant: 'icons' | 'images';
  link_kacheln: LinkTile[];
};

function Links(props: Props) {
  const { link_kacheln: linkKacheln, variant, blocktitle } = props;

  return (
    <div
      className={clsx('streamfield', 'links', {
        images: variant === 'images',
        icons: variant === 'icons',
      })}
    >
      {blocktitle ? <h2>{blocktitle}</h2> : null}
      <div className='links__link-container'>
        {linkKacheln.map((tile, index) => (
          <Link
            key={`${tile.title}-${index}`}
            href={
              tile.button_page ? removeBaseUrl(tile.button_page.full_url) : tile.button_url ?? ''
            }
            className='tile'
          >
            {tile.image && variant === 'icons' ? (
              <Image image={tile.image} className='tile__image' spec='fill-64x64'>
                <Rendition image={tile.image} mediaQuery='(max-width: 500px)' spec='fill-40x40' />
              </Image>
            ) : null}

            {tile.image && variant === 'images' ? (
              <Image
                image={tile.image}
                imageClassName='tile__image'
                className='tile__image'
                spec='fill-264x264'
              />
            ) : null}
            <div className='tile__title'>{tile.title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Links;
