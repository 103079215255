import NextImage, { getImageProps } from 'next/image';
import { ImageProps } from './types';
import { guessImageDimensions, renditionUrl } from './utils';

type Props = { imageClassName?: string } & ImageProps;

/**
 * Default Image Component capable of Renditions.
 *
 *
 * @returns The component creates picture tags with sources, if the Rendition component
 * is used as children, else the NextJs Image component is re-used.
 *
 * *Example: Image without additional renditions:*
 *
 * <Image image={image} spec='fill-1000x1000' />
 *
 * will create
 *
 * <img alt="..." loading="lazy" width="1000" height="1000" decoding="async" style="color:transparent" src="/_next/...">
 *
 *
 *
 * *Example: Creating images with multiple renditions:*
 *
 * <Image image={image} spec='fill-1000x1000'>
 *   <Rendition image={image} mediaQuery='(min-width: 400px)' spec='fill-200x200' />
 * </Image>
 *
 * will create
 *
 * <picture>
 *     <source media="(min-width: 400px)" srcset="/_next/..." width="200" height="200">
 *     <img alt="..." loading="lazy" width="1000" height="1000" decoding="async" style="color:transparent" src="/_next/...">
 * </picture>
 *
 */
function Image(props: Props) {
  const {
    image,
    width,
    height,
    spec,
    sizes,
    priority,
    children,
    fill,
    className,
    imageClassName,
    style,
    quality = 90,
  } = props;

  const dimensions = guessImageDimensions(image, spec, width, height, fill);
  const url = renditionUrl(image, spec);

  // Return images without renditions
  if (!children) {
    return (
      <NextImage
        src={url}
        alt={image.alt_text ?? ''}
        width={dimensions.width}
        height={dimensions.height}
        priority={priority}
        className={className}
        style={style}
        quality={quality}
        fill={fill}
      />
    );
  }

  // Use nextjs image optimization for default image
  const {
    props: { srcSet, ...rest },
  } = getImageProps({
    src: url,
    fill,
    width: dimensions.width,
    height: dimensions.height,
    alt: image.alt_text ?? '',
    sizes,
    style,
    priority,
  });

  return (
    <picture className={className}>
      {children}
      <img {...rest} srcSet={srcSet} alt={image.alt_text ?? ''} className={imageClassName} />
    </picture>
  );
}

export default Image;
