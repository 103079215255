'use client';

import { FormField, RequestStatus, Streamfield, WagtailImage } from '@/types';
import BannerImage from '@components/BannerImage';
import Button from '@components/Button';
import FormFields from '@components/Form/FormFields';
import Hero from '@components/Hero';
import RawHtml from '@components/RawHtml';
import Streamfields from '@streamfields/Streamfields';
import { useState } from 'react';

type Props = {
  title: string;
  subtitle: string;
  before_form: Streamfield[];
  after_form: Streamfield[];
  thank_you_text: string;
  form_fields: FormField[];
  banner_image?: WagtailImage | null;
};

function FormPage(props: Props) {
  const {
    title = '',
    subtitle,
    form_fields: formFields,
    before_form: beforeForm,
    after_form: afterForm,
    thank_you_text: thankYouText,
    banner_image: bannerImage,
  } = props;

  const [status, setStatus] = useState<RequestStatus>('idle');

  return (
    <div className='form-page'>
      {bannerImage ? <BannerImage image={bannerImage} /> : null}
      <Hero title={title} subtitle={subtitle} />
      {beforeForm ? <Streamfields fields={beforeForm} /> : null}
      <form
        id='formular'
        className='form'
        onSubmit={async event => {
          event.preventDefault();

          const formData = new FormData(event.currentTarget);

          const data: {
            [key: string]: FormDataEntryValue | FormDataEntryValue[];
          } = Object.fromEntries(formData);

          formFields.forEach(field => {
            if (field.field_type !== 'checkboxes') return;
            data[field.clean_name] = formData.getAll(field.clean_name);
          });

          try {
            setStatus('loading');
            const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v2/forms/`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                page: props.form_fields?.[0]?.page_id,
                form_data: data,
              }),
            });

            if (res.ok) {
              setStatus('success');
            } else {
              setStatus('error');
            }
          } catch (error) {
            setStatus('error');
          }
        }}
      >
        <FormFields formFields={formFields} />
        {status === 'success' ? (
          <div className='message message--success'>
            <RawHtml html={thankYouText} />
          </div>
        ) : null}
        {status === 'error' ? (
          <div className='message message--error'>
            <p>Leider ist etwas schief gegangen... Bitte versuchen Sie es später erneut...</p>
            <p>
              Alternativ können Sie Ihre Anfrage auch direkt an die E-Mail-Adresse{' '}
              <a href='mailto:YOUR EMAIL HERE'>YOUR EMAIL HERE</a> senden.
            </p>
          </div>
        ) : null}
        <Button
          type='submit'
          disabled={status === 'loading' || status === 'success'}
          className='form__submit'
        >
          Absenden
        </Button>
      </form>
      {afterForm ? <Streamfields fields={afterForm} /> : null}
    </div>
  );
}

export default FormPage;
