import RawHtml from '@components/RawHtml/RawHtml';

import { WagtailDocument, WagtailImage, WagtailPage } from '@/types';
import { Image, Rendition } from '@components/Image';
import { removeBaseUrl } from '@utils/url';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

type Props = {
  text: string;
  image: WagtailImage;
  buttons: {
    button_text: string;
    button_page: WagtailPage | null;
    button_url: string | null;
    document_link: WagtailDocument | null;
  }[];
  colorscheme: 'primary' | 'secondary';
  layout_option: 'image-right' | 'image-left';
};

function CTA(props: Props) {
  const { buttons, image, text, colorscheme, layout_option } = props;
  const t = useTranslations('Common');

  return (
    <div
      className={clsx('streamfield', 'breakout', 'cta', {
        'cta--primary': colorscheme === 'primary',
        'cta--secondary': colorscheme === 'secondary',
        'cta--inverse': layout_option === 'image-right',
      })}
    >
      <Image image={image} spec='fill-438x310' className='cta__image'>
        <Rendition image={image} mediaQuery='(min-width: 667px)' spec='fill-720x510' />
      </Image>
      <div className='cta__content'>
        <RawHtml html={text} className='cta__text' />
        {buttons?.length ? (
          <div className='cta__buttons'>
            {buttons.map(button =>
              button.document_link ? (
                <Link
                  key={button.document_link.title}
                  href={button.document_link.file}
                  passHref
                  className='button button--outline'
                >
                  {button.button_text}
                </Link>
              ) : (
                <Link
                  key={button.button_text}
                  href={
                    button.button_page
                      ? removeBaseUrl(button.button_page.full_url)
                      : button.button_url ?? ''
                  }
                  passHref
                  target={button.button_url ? '_blank' : undefined}
                  className='button button--outline'
                >
                  {button.button_text}
                </Link>
              ),
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CTA;
