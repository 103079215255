import { Image as ImageComponent } from '@components/Image';
import clsx from 'clsx';

import { WagtailImage } from '@/types';

type Props = {
  image: WagtailImage;
  fullWidth?: boolean;
  breakout?: boolean;
  alt_text?: string;
};

function Image(props: Props) {
  const { fullWidth, breakout, image, alt_text } = props;

  image.alt_text = alt_text || image.alt_text;

  return (
    <div
      className={clsx('streamfield', 'image', {
        'full-width': fullWidth,
        breakout,
      })}
    >
      <ImageComponent className='image__element' image={image} spec='fill-1440x810' />
    </div>
  );
}

export default Image;
