import Image from '@components/Image';
import RawHtml from '@components/RawHtml';
import clsx from 'clsx';
import Link from 'next/link';

import { WagtailImage, WagtailPage } from '@/types';
import { removeBaseUrl } from '@/utils/url';

type Card = {
  button_page: WagtailPage | null;
  button_url: string | null;
  buttonLink?: boolean;
  text: string;
  title: string;
  image: WagtailImage | null;
};

type Props = {
  roundImage?: boolean;
  title?: string;
  text?: string;
  bottomtext?: string;
  background: string;
  cards: Card[];
};

function Cards(props: Props) {
  const { cards, title, text, background, bottomtext, roundImage } = props;

  return (
    <div className={clsx('streamfield cards', background, roundImage ? 'roundImg' : '')}>
      <div className='cards__header'>
        <h2 className='cards__title'>{title}</h2>
        <RawHtml html={text} />
      </div>
      <div className='cards__wrapper'>
        {cards.map(card => (
          <div className='card' key={card.title}>
            {card.image ? (
              <div className='card__iconwrapper'>
                <Image
                  image={card.image}
                  className='card__icon'
                  spec={roundImage ? 'fill-300x300' : 'fill-78x78'}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            ) : null}
            <h2 className='card__title'>{card.title}</h2>
            <RawHtml className='card__text' html={card.text} />
            {card.button_page || card.button_url ? (
              <Link
                href={
                  card.button_page
                    ? removeBaseUrl(card.button_page.full_url)
                    : card.button_url ?? ''
                }
                passHref
                className={clsx(
                  card.buttonLink ? 'button button--primary card__link' : 'card__link',
                )}
              >
                Zur Seite
              </Link>
            ) : null}
          </div>
        ))}
      </div>
      <RawHtml className='cards__footer container' html={bottomtext} />
    </div>
  );
}

export default Cards;
