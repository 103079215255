import Streamfields from '@streamfields/Streamfields';

type ColumnEntry = {
  type: string;
};

type ColumnValue = {
  [key: string]: ColumnEntry[];
};

type Column = {
  type: string;
  value: ColumnValue;
};

type Props = {
  [key: string]: Column;
};

function Columns(props: Props) {
  return (
    <div className='streamfield columns'>
      {Object.values(props).map(column => (
        <div className='columns__grid' data-type={column.type} key={column.type}>
          {Object.entries(column.value).map(([key, value]) => (
            <div key={key}>
              {/* @ts-expect-error */}
              <Streamfields fields={value} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Columns;
