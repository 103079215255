import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import Label from '@components/Form/Label';

type Props = {
  label: string;
  id: string;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
  labels: string[];
};

function Slider(props: Props) {
  const { id, label, min = 1, max = 6, step = 1, defaultValue = 1, labels } = props;
  return (
    <div className='slider-input'>
      <Label text={label} htmlFor={id} />
      <div className='slider-input__container'>
        <SliderPrimitive.Root
          className='slider-input__root'
          id={id}
          name={id}
          orientation='horizontal'
          defaultValue={[defaultValue]}
          step={step}
          min={min}
          max={max}
        >
          <SliderPrimitive.Track className='slider-input__track'>
            <SliderPrimitive.Range className='slider-input__range' />
          </SliderPrimitive.Track>
          <SliderPrimitive.Thumb className='slider-input__thumb' />
        </SliderPrimitive.Root>
      </div>
      <div className='slider-input__text'>
        {labels.map(labelText => (
          <p key={labelText}>{labelText}</p>
        ))}
      </div>
    </div>
  );
}

export default Slider;
