/* eslint-disable react/no-array-index-key */
import LoadingSkeleton from '@components/LoadingSkeleton';
import Link from 'next/link';
import { Props } from './Tickets';

function Loading(props: Props) {
  const { title, linkText, count } = props;
  return (
    <div className='streamfield tickets' aria-busy='true' aria-live='polite'>
      <h2 className='tickets__title'>{title}</h2>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <div className='ticket' key={index}>
            <LoadingSkeleton
              className='ticket__image'
              width='100%'
              height='266px'
              noMargin
              noBorder
            />
            <div className='ticket__content'>
              <LoadingSkeleton className='ticket__title' width='50%' />
              <LoadingSkeleton className='ticket__times/' width='40%' />
              <LoadingSkeleton className='ticket__description' width='70%' />
            </div>
            <i className='ticket__caret' aria-hidden='true' />
          </div>
        ))}
      <Link
        href='https://e-shop.neanderthal.de/de/guided-tours'
        className='tickets__link button button--outline'
      >
        {linkText}
      </Link>
    </div>
  );
}

export default Loading;
