'use client';

/* eslint-disable react/no-unused-prop-types */
import { WagtailDocument, WagtailImage, WagtailPage } from '@/types';
import { useMemo, useReducer } from 'react';
import RelatedItem from './RelatedItem';

export type SchoolEvent = {
  id: number;
  image: WagtailImage;
  related_items: any[];
  title: string;
  subtitle: string;
  text: string;
  start: null | string;
  end: null | string;
  age_group: AgeGroup[];
  attachment: WagtailDocument | null;
  detail_page: WagtailPage | null;
  event_type: EventType;
  locale: 1;
};

type AgeGroup = 'Kita' | 'Grundschule' | 'Sek1' | 'Sek2' | 'OGS';
type EventType = 'Führungen' | 'Workshops';

type Props = {
  age_group: AgeGroup[];
  related_items: SchoolEvent[];
  show_filters: boolean;
};

type State = {
  age_group: AgeGroup | string;
  event_type: EventType | string;
};

const initialState = {
  age_group: '',
  event_type: '',
};

function SchoolEvents(props: Props) {
  const { related_items, show_filters } = props;
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initialState,
  );

  const filteredItems = useMemo(
    () =>
      related_items
        ?.filter(item => (state.event_type ? item.event_type === state.event_type : true))
        ?.filter(item => (state.age_group ? item.age_group.includes(state.age_group) : true)),
    [state.age_group, state.event_type],
  );

  return (
    <div className='streamfield events-listing'>
      {show_filters ? (
        <div className='events-listing__filters'>
          <select value={state.event_type} onChange={e => dispatch({ event_type: e.target.value })}>
            <option value=''>Alle Veranstaltungen</option>
            <option value='Führungen'>Führungen</option>
            <option value='Workshops'>Workshops</option>
          </select>
          <select value={state.age_group} onChange={e => dispatch({ age_group: e.target.value })}>
            <option value=''>Alle Altersgruppen</option>
            <option value='Kita'>Kita</option>
            <option value='Grundschule'>Grundschule</option>
            <option value='SEK1'>SEK I</option>
            <option value='SEK2'>SEK II</option>
            <option value='OGS'>OGS</option>
          </select>
        </div>
      ) : null}
      {filteredItems?.length ? (
        <div className='events-listing__related-items'>
          {filteredItems.map(item => (
            <RelatedItem key={item.id} item={item} />
          ))}
        </div>
      ) : (
        <p>Für diese Auswahl sind leider keine Veranstaltungen vorhanden.</p>
      )}
    </div>
  );
}

export default SchoolEvents;
