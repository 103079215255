import React from 'react';
import Tickets from '@components/Tickets';

type Props = { title: string; link_text: string; count: number };

function Events(props: Props) {
  const { title, link_text, count } = props;

  return <Tickets title={title} linkText={link_text} count={count} />;
}

export default Events;
