type Props = {
  quote: string;
  caption?: string;
};

function Quote(props: Props) {
  const { quote, caption } = props;
  return (
    <div className='streamfield quote'>
      <figure>
        <blockquote>
          <q>{quote}</q>
        </blockquote>
        {caption ? <figcaption>– {caption}</figcaption> : null}
      </figure>
    </div>
  );
}

export default Quote;
