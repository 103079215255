import { Streamfield } from '@/types';
import Streamfields from '@streamfields/Streamfields';
import clsx from 'clsx';
import React from 'react';

type Props = {
  colorscheme: 'lightgrey';
  content: Streamfield[];
};

function Container(props: Props) {
  const { content, colorscheme } = props;
  return (
    <div
      className={clsx('streamfield', 'container-field', 'full-width', {
        'container-field--lightgrey': colorscheme === 'lightgrey',
      })}
    >
      {content ? <Streamfields fields={content} /> : null}
    </div>
  );
}

export default Container;
