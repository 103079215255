import Accordions from './Accordions';
import AnchorBlock from './AnchorBlock';
import Buttons from './Buttons';
import CTA from './CTA';
import Cards from './Cards';
import Columns from './Columns';
import Container from './Container';
import Divider from './Divider';
import Events from './Events';
import FeatureTiles from './FeatureTiles';
import GeneralEvents from './GeneralEvents';
import Image from './Image';
import InfoBlock from './InfoBlock';
import Links from './Links';
import Message from './Message';
import ParallelInfo from './ParallelInfo';
import Quote from './Quote';
import Richtext from './Richtext';
import SchoolEvents from './SchoolEvents';
import Table from './Table';
import TextImage from './TextImage';
import Video from './Video';

const streamfields = {
  cta: CTA,
  links: Links,
  message: Message,
  full_richtext: Richtext,
  simple_richtext: Richtext,
  table: Table,
  code: Richtext,
  cards: Cards,
  accordion: Accordions,
  image: Image,
  quote: Quote,
  columns: Columns,
  feature_tiles: FeatureTiles,
  video: Video,
  buttons: Buttons,
  container: Container,
  text_image: TextImage,
  parallel_info: ParallelInfo,
  events: Events,
  divider: Divider,
  info_block: InfoBlock,
  school_events: SchoolEvents,
  anchor_block: AnchorBlock,
  general_events: GeneralEvents,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
