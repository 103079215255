import Dialog from '@components/Dialog';
import Image from '@components/Image';
import RawHtml from '@components/RawHtml';
import WorkshopRegistrationForm from '@components/WorkshopRegistrationForm';
import Link from 'next/link';

import type { SchoolEvent } from './SchoolEvents';
import { useTranslations } from 'next-intl';
import { removeBaseUrl } from '@utils/url';

type Props = { item: SchoolEvent };

function RelatedItem(props: Props) {
  const { item } = props;

  const t = useTranslations('Common');

  return (
    <div key={item.id} className='related-item'>
      <Image
        image={item.image}
        className='related-item__image'
        spec='width-600'
        width={268}
        height={179}
      />
      <div className='related-item__content'>
        <h3 className='related-item__title'>{item.title}</h3>
        {item.subtitle ? (
          <p className='related-item__subtitle'>
            <strong>{item.subtitle}</strong>
          </p>
        ) : null}
        <RawHtml html={item.text} />
        {item?.attachment ? (
          <Link href={item.attachment.file} className='related-item__attachment' target='_blank'>
            Weitere Informationen in diesem PDF
          </Link>
        ) : null}
        <div className='related-item__links'>
          {item.detail_page ? (
            <div className='related-item__registration'>
              <Link
                className='button button--outline related-item__link'
                href={removeBaseUrl(item.detail_page.full_url)}
              >
                {t('Mehr erfahren')}
              </Link>
            </div>
          ) : null}
          <div className='related-item__registration'>
            <Dialog
              title={item.title}
              description={<RawHtml html={item.text} />}
              trigger={t('Online-Anmeldung')}
            >
              <WorkshopRegistrationForm
                title={item.title}
                events={item.related_items
                  .filter(event => event.related_item.event_type === 'Führungen')
                  .map(event => event.related_item.title)}
                workshops={item.related_items
                  .filter(workshop => workshop.related_item.event_type === 'Workshops')
                  .map(workshop => workshop.related_item.title)}
              />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RelatedItem;
