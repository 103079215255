import { WagtailImage } from '@/types';
import { Image } from '@components/Image';
import RawHtml from '@components/RawHtml';
import clsx from 'clsx';

type Props = {
  text: string;
  image: WagtailImage;
  colorscheme: 'primary' | 'secondary';
  layout_option: 'image-right' | 'image-left';
};

function TextImage(props: Props) {
  const { image, text, colorscheme, layout_option } = props;

  return (
    <div
      className={clsx('streamfield', 'breakout', 'text-image', {
        'text-image--primary': colorscheme === 'primary',
        'text-image--secondary': colorscheme === 'secondary',
        'text-image--inverse': layout_option === 'image-right',
      })}
    >
      <Image image={image} className='text-image__image' sizes='500px 50vw, 100vw' />
      <div className='text-image__content'>
        <RawHtml html={text} className='text-image__text' />
      </div>
    </div>
  );
}

export default TextImage;
