import { FormField } from '@/types';
import Select from '@components/Form/Select';

function SelectField(props: FormField) {
  const { choices, clean_name, label, default_value } = props;

  return (
    <Select
      items={choices.split(',').map(choice => ({ text: choice, value: choice }))}
      defaultValue={default_value}
      ariaLabel={label}
      label={label}
      id={clean_name}
    />
  );
}

export default SelectField;
