import { Streamfield } from '@/types';
import Streamfields from '@streamfields/Streamfields';

type Props = {
  custom_id: string;
  content: Streamfield[];
};

function AnchorBlock(props: Props) {
  const { custom_id, content } = props;

  return (
    <div id={custom_id} className='anchor full-width'>
      {content ? <Streamfields fields={content} /> : null}
    </div>
  );
}

export default AnchorBlock;
