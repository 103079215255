import Fieldset from '@components/Form/Fieldset';
import React from 'react';

import { fields } from '@components/Form/FormFields';
import { FormField } from '@/types';

type Props = {
  legend: string;
  fields: FormField[];
};

function FieldsetField(props: Props) {
  const { legend, fields: formFields } = props;

  return (
    <Fieldset legend={legend}>
      {formFields
        .sort((a, b) => a.sort_order - b.sort_order)
        .map(field => {
          const Component = fields[field.field_type];

          if (!Component) {
            return (
              <h2 key={field.id}>
                Unknown form field <strong>{field.field_type}</strong>
              </h2>
            );
          }

          return <Component {...field} key={field.id} />;
        })}
    </Fieldset>
  );
}

export default FieldsetField;
