import Input from '@components/Form/Input';
import React from 'react';
import { FormField } from '@/types';

function SingleLineField(props: FormField) {
  const { label, clean_name: cleanName, help_text: helpText, required } = props;

  return (
    <Input label={label} type='text' id={cleanName} placeholder={helpText} required={required} />
  );
}

export default SingleLineField;
