import React from 'react';

import RawHtml from '@components/RawHtml';

type Props = {
  title: string;
  subtitle?: string | null;
};

function Hero(props: Props) {
  const { title, subtitle } = props;

  return (
    <div className='hero'>
      <h1 className='hero__title'>{title}</h1>
      {subtitle ? <RawHtml className='hero__subtitle' html={subtitle} /> : null}
    </div>
  );
}

export default Hero;
