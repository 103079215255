'use client';

import { useTranslations } from 'next-intl';

function PrintButton() {
  const t = useTranslations('Programmangebot');

  return (
    <button
      className='print'
      onClick={() => {
        window.print();
      }}
    >
      {t('Seite drucken oder als PDF speichern')}
    </button>
  );
}

export default PrintButton;
