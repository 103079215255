import Textarea from '@components/Form/Textarea';
import React from 'react';
import { FormField } from '@/types';

function MultiLineField(props: FormField) {
  const { label, clean_name: cleanName, help_text: helpText, required } = props;

  return <Textarea label={label} id={cleanName} placeholder={helpText} required={required} />;
}

export default MultiLineField;
