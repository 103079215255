import React, { Suspense } from 'react';
import type { Props } from './Tickets';
import Loading from './Loading';

const Tickets = React.lazy(() => import('./Tickets'));

function SuspendedTickets(props: Props) {
  return (
    <Suspense fallback={<Loading {...props} />}>
      <Tickets {...props} />
    </Suspense>
  );
}

export default SuspendedTickets;
