import Input from '@components/Form/Input';
import React from 'react';
import { FormField } from '@/types';

function TimeField(props: FormField) {
  const {
    label,
    clean_name: cleanName,
    help_text: helpText,
    default_value: defaultValue,
    required,
  } = props;

  return (
    <Input
      label={label}
      type='time'
      id={cleanName}
      placeholder={helpText}
      defaultValue={defaultValue || '10:00'}
      required={required}
    />
  );
}

export default TimeField;
