import { WagtailImage } from '@/types';
import { Image } from '@components/Image';
import RawHtml from '@components/RawHtml';

type Props = {
  text: string;
  icon: WagtailImage;
};

function InfoBlock(props: Props) {
  const { icon, text } = props;

  return (
    <div className='streamfield info-block'>
      <div className='info-block__icon-wrapper'>
        <Image image={icon} className='info-block__icon' />
      </div>
      <div className='info-block__content'>
        <RawHtml html={text} className='info-block__text' />
      </div>
    </div>
  );
}

export default InfoBlock;
