import { ParsedUrlQuery } from 'querystring';

const baseUrl = process.env.WAGTAIL_BASE_URL ?? process.env.NEXT_PUBLIC_WAGTAIL_BASE_URL;

function removeBaseUrl(url: string) {
  return url?.replace(baseUrl!, '') ?? '/';
}

function addBaseUrl(url: string) {
  return `${baseUrl!}${url}`;
}

function toQuerystring(query: ParsedUrlQuery) {
  return Object.entries(query)
    .filter(Boolean)
    .map(entry => entry.join('='))
    .join('&');
}

export { addBaseUrl, removeBaseUrl, toQuerystring };
