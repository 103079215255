'use client';

/* eslint-disable react/no-unused-prop-types */
import { WagtailDocument, WagtailImage, WagtailPage } from '@/types';
import { useMemo, useReducer } from 'react';
import RelatedItem from './RelatedItem';
import { useTranslations } from 'next-intl';
import RawHtml from '@components/RawHtml';

export type GeneralEvent = {
  id: number;
  image: WagtailImage;
  related_items: any[];
  title: string;
  subtitle: string;
  text: string;
  start: null | string;
  end: null | string;
  target_audience: TargetAudience[];
  attachment: WagtailDocument | null;
  event_type: EventType;
  visitate_link?: string;
  detail_page?: WagtailPage;
  email: string | null;
  locale: 1;
};

const TARGET_AUDIENCE = [
  'Firmen und Gruppen',
  'Outdoor',
  'Kinder ab 5 Jahren',
  'Kinder ab 8 Jahren',
] as const;

type TargetAudience = (typeof TARGET_AUDIENCE)[number];

const EVENT_TYPE = ['Führungen', 'Workshops', 'Steinzeitgeburtstage', 'Aktivprogramme'] as const;
type EventType = (typeof EVENT_TYPE)[number];

type State = {
  target_audience: TargetAudience | string;
  event_type: EventType | string;
};

const initialState = {
  target_audience: '',
  event_type: '',
};

type Props = {
  title?: string;
  description?: string;
  target_audience: TargetAudience[];
  event_type: EventType[];
  related_items: GeneralEvent[];
  show_filters: boolean;
};

function GeneralEvents(props: Props) {
  const { related_items, show_filters, title, description } = props;
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initialState,
  );
  const t = useTranslations('Common');

  const filteredItems = useMemo(
    () =>
      related_items
        ?.filter(item => (state.event_type ? item.event_type.includes(state.event_type) : true))
        ?.filter(item =>
          state.target_audience ? item.target_audience.includes(state.target_audience) : true,
        ),
    [state.target_audience, state.event_type],
  );

  return (
    <div className='events-listing'>
      {title ? <h2>{title}</h2> : null}
      {description ? <RawHtml html={description} /> : null}
      {show_filters ? (
        <div className='events-listing__filters'>
          <select value={state.event_type} onChange={e => dispatch({ event_type: e.target.value })}>
            <option value=''>{t('Alle Veranstaltungen')}</option>
            {(props.event_type.length ? props.event_type : EVENT_TYPE).map(event_type => (
              <option key={event_type} value={event_type}>
                {t(event_type)}
              </option>
            ))}
          </select>
          <select
            value={state.target_audience}
            onChange={e => dispatch({ target_audience: e.target.value })}
          >
            <option value=''>{t('Alle Zielgruppen')}</option>
            {(props.target_audience.length ? props.target_audience : TARGET_AUDIENCE).map(
              target_audience => (
                <option key={target_audience} value={target_audience}>
                  {t(target_audience)}
                </option>
              ),
            )}
          </select>
        </div>
      ) : null}
      {filteredItems?.length ? (
        <div className='events-listing__related-items'>
          {filteredItems.map(item => (
            <RelatedItem key={item.id} item={item} />
          ))}
        </div>
      ) : (
        <p>Für diese Auswahl sind leider keine Veranstaltungen vorhanden.</p>
      )}
    </div>
  );
}

export default GeneralEvents;
