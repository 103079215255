import { WagtailImage, WagtailVideo } from '@/types';

type Props = {
  poster: WagtailImage | null;
  video: WagtailVideo;
};

function Video(props: Props) {
  const { poster, video } = props;

  return (
    <div className='streamfield video'>
      <video preload='metadata' width='100%' controls poster={poster?.file ?? ''}>
        <source src={video.file} type={`video/${video.file.split('.').at(-1) ?? 'mp4'}`} />
        <track kind='captions' />
      </video>
    </div>
  );
}

export default Video;
