import RawHtml from '@components/RawHtml/RawHtml';
import Link from 'next/link';

import { WagtailPage } from '@/types';
import { removeBaseUrl } from '@/utils/url';
import clsx from 'clsx';

type Props = {
  title: string | null;
  text_block_1: string;
  button_text_block_1: string;
  button_page_block_1: WagtailPage | null;
  button_url_block_1: string | null;
  colorscheme_block_1: 'primary' | 'secondary';
  text_block_2: string;
  button_text_block_2: string;
  button_page_block_2: WagtailPage | null;
  button_url_block_2: string | null;
  colorscheme_block_2: 'primary' | 'secondary';
  annotation: string | undefined;
};

function ParallelInfo(props: Props) {
  const {
    title,
    text_block_1,
    button_text_block_1,
    button_page_block_1,
    button_url_block_1,
    colorscheme_block_1,
    text_block_2,
    button_text_block_2,
    button_page_block_2,
    button_url_block_2,
    colorscheme_block_2,
    annotation,
  } = props;

  return (
    <div className='streamfield parallel-info'>
      {title ? <h2 className='parallel-info__heading'>{title}</h2> : null}
      <div className='parallel-info__wrapper'>
        <div
          className={clsx('parallel-info__first parallel-info__content', {
            'color--primary': colorscheme_block_1 === 'primary',
            'color--secondary': colorscheme_block_1 === 'secondary',
          })}
        >
          <RawHtml html={text_block_1} className='parallel-info__text' />
          <Link
            href={
              button_page_block_1
                ? removeBaseUrl(button_page_block_1.full_url)
                : button_url_block_1 ?? ''
            }
            passHref
            className='button parallel-info__button'
          >
            {button_text_block_1}
          </Link>
        </div>

        <div
          className={clsx('parallel-info__second parallel-info__content', {
            'color--primary': colorscheme_block_2 === 'primary',
            'color--secondary': colorscheme_block_2 === 'secondary',
          })}
        >
          <RawHtml html={text_block_2} className='parallel-info__text' />
          <Link
            href={
              button_page_block_2
                ? removeBaseUrl(button_page_block_2.full_url)
                : button_url_block_2 ?? ''
            }
            passHref
            className='button parallel-info__button'
          >
            {button_text_block_2}
          </Link>
        </div>
      </div>
      {annotation ? <RawHtml html={annotation} className='parallel-info__annotation' /> : null}
    </div>
  );
}

export default ParallelInfo;
