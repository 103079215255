'use client';

import * as D from '@radix-ui/react-dialog';
import { PropsWithChildren, ReactNode } from 'react';

import CloseIcon from '@icons/close.svg';

type Props = {
  title: string;
  description: ReactNode;
  trigger: ReactNode;
};

function Dialog(props: PropsWithChildren<Props>) {
  const { children, title, description, trigger } = props;

  return (
    <D.Root>
      <D.Trigger className='button dialog__trigger' type='button'>
        {trigger}
      </D.Trigger>
      <D.Portal>
        <D.Overlay className='dialog__overlay' />
        <D.Content className='dialog__content'>
          <D.Close className='dialog__close' aria-label='close'>
            <CloseIcon width={20} height={20} viewBox='0 0 25 25' color='black' />
          </D.Close>
          <D.Title className='dialog__title'>{title}</D.Title>
          <D.Description className='dialog__description' asChild>
            {description}
          </D.Description>
          {children}
        </D.Content>
      </D.Portal>
    </D.Root>
  );
}

export default Dialog;
